<template>
    <div class="rentBox">
        <div class="OceanShippingServer">
            <!-- 订单号 -->
            <div class="orderNumber">
                <input type="text" placeholder="订单号" v-model="orderNumber" @input="inputNum1">
            </div>
            <div class="orderStatus">
                <el-select v-model="departureStationValue" placeholder="出发站" v-el-select-loadmore="loadmore" filterable
                    :filter-method="remoteMethod" @focus="changeFous">
                    <el-option v-for="(item, index) in departureStationList" :key="index" :label="item.city"
                        :value="item.city">
                    </el-option>
                </el-select>
            </div>
            <div class="orderStatus">
                <el-select v-model="destinationValue" placeholder="出发站" v-el-select-loadmore="loadmore" filterable
                    :filter-method="remoteMethod1" @focus="changeFous">
                    <el-option v-for="(item, index) in destinationListList" :key="index" :label="item.city"
                        :value="item.city">
                    </el-option>
                </el-select>
            </div>
            <!-- 订单状态 -->
            <div class="orderStatus">
                <el-select v-model="statusValue" placeholder="订单状态">
                    <el-option v-for="(item, index) in orderstatus" :key="index" :label="item.value"
                        :value="item.status1">
                    </el-option>
                </el-select>
            </div>
            <!-- 下单范围日期 -->
            <div class="orderRangeDate">
                <el-date-picker prefix-icon="" v-model="value1" type="date" placeholder="下单范围日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
            </div>
            <!-- 搜索按钮 -->
            <div class="server_Btn" @click=handleServer><i class="el-icon-search"></i><span>搜索</span></div>
        </div>

        <div class="tables">
            <el-table :data="orderDetailList" style="width: 100%" border :header-row-style="{ height: '50px' }"
                :header-cell-style="{ background: 'rgba(153, 153, 153, .1)' }" :row-style="{ height: '56px' }">
                <el-table-column prop="orderNo" label="订单号" width="200">
                </el-table-column>
                <el-table-column label="订单状态" width="130">
                    <template slot-scope="scope">
                        <p v-if="scope.row.status == 0">已下单</p>
                        <p v-if="scope.row.status == 1">已付款</p>
                        <p v-if="scope.row.status == 2">已租赁</p>
                        <p v-if="scope.row.status == 3">已还箱</p>
                        <p v-if="scope.row.status == 4">已完成</p>
                    </template>
                </el-table-column>
                <el-table-column prop="suitcase" label="提箱堆场" width="150">
                </el-table-column>
                <el-table-column prop="destination" label="还箱城市" width="150">
                </el-table-column>
                <!-- <el-table-column prop="destination" label="还箱堆场" width="150">
                </el-table-column> -->
                <el-table-column prop="cupboard" label="箱型" width="150">
                </el-table-column>
                <el-table-column prop="price" label="订单箱费" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.currency | getCurrency }}</span>
                        <span>{{ scope.row.price }}</span>
                    </template>
                </el-table-column>
                <el-table-column label="订单总价" width="150">
                    <template slot-scope="scope">
                        <span>{{ scope.row.currency | getCurrency }}</span>
                        <span>{{ scope.row.price * scope.row.cupboardNum }}</span>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="otherExpenses" label="是否使用优惠券" width="150">
                </el-table-column> -->
                <!-- <el-table-column prop="orderTime" label="优惠后金额" width="150">
                </el-table-column>
                <el-table-column prop="orderTime" label="超期标准" width="150">
                </el-table-column>
                <el-table-column prop="orderTime" label="超期标准" width="150">
                </el-table-column> -->
                <el-table-column label="提交时间" width="150">
                    <template slot-scope="scope">
                        <div>
                            {{ scope.row.createTime.split(" ")[0] }}
                        </div>
                    </template>

                </el-table-column>

                <!--  -->
                <el-table-column fixed="right" label="操作" width="300" class-name="nana">
                    <template slot-scope="scope" class="nasna">
                        <el-button @click="handleDetail(scope.row)" type="text" size="small">详情</el-button>
                        <!-- <el-button @click="handleClick(scope.row)" type="text" size="small">签署文件</el-button> -->
                        <el-button type="text" @click="handleDelete(scope.row)" size="small">撤单</el-button>
                        <el-button type="text" @click=handleDetail(scope.row) size="small">支付记录</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <div class="paging">
                <el-pagination background @current-change="handleSizeChange" layout="prev, pager, next"
                    :total="paging.total" :page-size="paging.size">
                </el-pagination>
            </div>

        </div>

        <!-- 详情 -->
        <div class="detail" v-show="detailS">
            <div class="detailBox">
                <div class="detailTitle"><span>订单详情</span></div>

                <div class="detailName">
                    <div class="detailNameLeft">
                        <div class="img">
                            <img src="../../assets/BookingSpace/touxiang.png" alt="">
                        </div>
                        <!-- <div class="names">
                            <p>某某某</p>
                            <p>宁波顺欧供应链管理有限公司</p>
                        </div> -->
                    </div>
                    <div class="detailNameRight">
                        <p>下单时间：{{ detailList.createTime }}</p>
                        <p>订单编号：{{ detailList.orderNo }}</p>
                    </div>
                </div>

                <div class="orderInformation">
                    <div class="orderTitle">订单信息及支付记录</div>
                    <div class="orderContent">
                        <div class="drderL">
                            <ul>
                                <!-- <li>联系人</li> -->
                                <li>预留电话</li>
                                <li>下单账号</li>
                                <li>取箱堆场/还箱堆场</li>
                                <!-- <li>还箱城市</li> -->
                                <li>订购箱型</li>
                                <li>订购数量</li>
                                <li>已提箱时长</li>
                            </ul>
                        </div>
                        <div class="drderR">
                            <ul>
                                <!-- <li>联系人</li> -->
                                <li>{{ detailList.mobile }}</li>
                                <li>{{ authInfo }}</li>
                                <li>{{ detailList.suitcase }}/待定</li>
                                <!-- <li>还箱城市</li> -->
                                <li>{{ detailList.cupboard }}</li>
                                <li>{{ detailList.cupboardNum }}</li>
                                <li>{{ detailList.createTime }}</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="formation">
                    <div class="orderTitle">订单信息及支付记录</div>
                    <div class="formationContent">
                        <div class="formationContentL">
                            <ul>
                                <li>顾客下单</li>
                                <li>商家确认</li>
                                <li>支付确认</li>
                            </ul>
                            <ul>
                                <li>{{ detailList.createTime }} </li>
                                <li>-</li>
                                <li>
                                    <p v-if="detailList.status == 0">已下单</p>
                                    <p v-if="detailList.status == 1">已付款</p>
                                    <p v-if="detailList.status == 2">已租赁</p>
                                    <p v-if="detailList.status == 3">已还箱</p>
                                    <p v-if="detailList.status == 4">已完成</p>
                                </li>
                            </ul>
                        </div>
                        <div class="formationContentR">
                            <ul>
                                <li>确认取箱</li>
                                <li>确认还箱</li>
                                <li>理赔支付</li>
                                <li>理赔支付</li>
                            </ul>
                            <ul>
                                <li>-</li>
                                <li>-</li>
                                <li>-</li>
                                <li>-</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div class="close">
                    <div class="btn" @click="handleClose">关闭</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { baseUrl } from '../../tools/baseUrl';
import { homecityList } from '../../tools/ajax'
export default {
    name: "RentBox_Component",
    data() {
        return {
            value: "",
            departureStationValue: "",  //出发站值
            departureStationList: [],  //出发站数据
            destinationListList: [],  //目的站数据
            destinationValue: "", //目的站值
            orderstatus: [  //订单状态
                {
                    status1: -1,
                    value: "已撤单"
                }, {
                    status1: 0,
                    value: "已下单"
                }, {
                    status1: 1,
                    value: "已付款"
                }, {
                    status1: 2,
                    value: "已租赁"
                },
                {
                    status1: 3,
                    value: "已还箱"
                }, {
                    status1: 4,
                    value: "已完成"
                }
            ],
            statusValue: "",//订单状态值
            detailS: false, //详情
            detailList: '', //详情列表
            orderDetailList: [], //表格
            orderNumber: "", //订单号
            inquiryOrderList: [{ //状态
                states: -2,
                value: '全部'
            }, {
                states: 1,
                value: '询价中'
            }, {
                states: 2,
                value: '待委托'
            }, {
                states: 3,
                value: '运输中'
            }, {
                states: 4,
                value: '已到港'
            }, {
                states: 5,
                value: '已完成'
            }],
            inquiryOrderValue: -2, //状态值
            orderType: "",
            otherExpenses: "",//下单时间
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            },],
            value1: "",

            nquiryListPageNo: 1, //记录页
            formData: {
                pageNo: 1, //当前页
            },
            paging: {
                total: 0,
                size: 5,
            },
            authInfo: '', //
        }
    },
    created() {
        this.getboxOrder()

        homecityList({ pageNo: '1' }).then((res) => {
            this.departureStationList = res.result.records;
            this.destinationListList = res.result.records;
        })

    },
    methods: {
        getboxOrder() {
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/boxOrder/list`, {
                //参数列表
                params: {
                    pageNo: this.inquiryListPageNo,  //页码
                    status: this.inquiryOrderValue, //状态
                },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    // console.log(res.data.result)
                    this.orderDetailList = res.data.result.records;
                    this.paging.total = res.data.result.total;
                    // this.paging.size = res.data.result.soze;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        handleSizeChange(val) { //分页
            // console.log(val);
            var token = sessionStorage.getItem("token")
            axios.get(`${baseUrl}/boxOrder/list`, {
                params: {
                    pageNo: val,  //页码
                    status: this.inquiryOrderValue, //状态
                },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {
                    this.orderDetailList = res.data.result.records;
                })
                .catch(err => {
                    console.error(err);
                })
        },
        inputNum1(e) {  //限制输入数字
            this.orderNumber = e.target.value;
            this.orderNumber = this.orderNumber.replace(/[^\d.]/g, '')// 清除"数字"和"."以外的字符
            this.orderNumber = this.orderNumber.replace(/^\./g, '')// 验证第一个字符是数字而不是字符
            this.orderNumber = this.orderNumber.replace(/\.{2,}/g, '.')// 只保留第一个.清除多余的
            this.orderNumber = this.orderNumber.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.');
        },


        changeFous() {
            // console.log("%%");
            homecityList({ pageNo: '1' }).then((res) => {
                this.departureStationList = res.result.records;
                this.destinationListList = res.result.records;
            })
        },
        remoteMethod(value) { //
            // console.log("$$$", value);
            this.departureStationValue = value;
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        // console.log("value", value);
                        // console.log("---", res.data.result.records);
                        // this.departureStationList.unshift(res.data.result.records);
                        this.departureStationList = res.data.result.records;
                        // console.log(this.departureStationList);
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    // console.log(res.result);
                    this.departureStationList = res.result.records;
                    // this.destinationListList = res.result.records;
                });
            }

        },
        remoteMethod1(value) {
            this.destinationValue = value;
            if (value) {
                axios.get(`${baseUrl}/basic/cityList`, {
                    params: { name: value }
                })
                    .then(res => {
                        // console.log("---", res.data.result.records);
                        // this.departureStationList = res.data.result.records;
                        this.destinationListList = res.data.result.records;
                    })
                    .catch(err => {
                        console.error(err);
                    })
            } else {
                homecityList().then(res => {
                    // console.log(res.result);
                    // this.departureStationList = res.result.records;
                    this.destinationListList = res.result.records
                });
            }
        },

        handleServer() {
            // console.log("@");
        },
        handleDetail(row) {  //详情
            var token = sessionStorage.getItem("token");
            var authInfo = JSON.parse(localStorage.getItem("authInfo"));
            // console.log(authInfo.tel);
            this.authInfo = authInfo.tel
            axios.get(`${baseUrl}/boxOrder/queryById`, {
                params: { id: row.id },
                //请求头配置  
                headers: { 'authorization': token }
            })
                .then(res => {

                    console.log(res.data.result)
                    if (res.data.code == 200) {
                        this.detailS = true;
                        this.detailList = res.data.result;

                    }

                })
                .catch(err => {
                    console.error(err);
                })

        },
        handleClose() {  //关闭详情
            this.detailS = false;
        },
        handleDelete(row) {  //撤单
            var token = sessionStorage.getItem("token")
            axios.delete(`${baseUrl}/boxOrder/delete`, {
                params: {
                    id: row.id,
                },
                headers: {
                    'authorization': token
                }
            })
                .then(res => {

                    if (res.data.success == true) {
                        this.$message({
                            showClose: true,
                            message: '删除成功',
                            type: 'error'
                        });
                        // console.log("撤销", res)
                        this.getboxOrder()
                    } else {
                        let message = res.data.message;
                        this.$message({
                            showClose: true,
                            message: message,
                            type: 'error'
                        });
                        return false
                    }

                })
                .catch(err => {
                    console.error(err);
                })

        },


        // #region //搜索框

        // 懒加载
        loadmore() {
            this.formData.pageNo++;
            this.getList(this.formData);
        },
        // 下拉刷新
        getList(formData) {
            homecityList({ pageNo: formData.pageNo }).then((res) => {
                const _res = res.result.records;
                this.departureStationList = [...this.departureStationList, ..._res];
                this.destinationListList = [...this.destinationListList, ..._res];
            })
        }
        // #endregion
    }
}
</script>

<style lang="scss" scoped>
.rentBox {
    // position: relative;
    // min-height: 690px;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
}

.OceanShippingServer {
    display: flex;
    align-items: center;
    padding-left: 32px;
    padding-top: 24px;
    // height: 40px;
    // height: 520px;   

    .orderNumber {
        width: 180px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        input {
            width: 100%;
            height: 100%;
            padding-left: 16px;
            font-size: 14px;
            font-weight: 400;
            color: #333333;
            border-radius: 4px 4px 4px 4px;
            border: 1px solid #C0C4CC;
            cursor: pointer;
        }

        input:focus {
            outline: 1px solid #409EFF;
        }
    }

    .orderStatus {
        width: 180px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        margin-left: 32px;

        .el-select {
            width: 100%;
            height: 100%;
        }
    }

    .orderRangeDate {
        width: 180px;
        height: 40px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;
        // border: 1px solid #999999;
        margin-left: 32px;

        .el-date-editor {
            width: 100%;
            height: 100%;

        }

        ::v-deep .el-input__prefix {
            display: none;
        }
    }

    .server_Btn {
        width: 120px;
        height: 40px;
        background: #FFC13B;
        box-shadow: 0px 3px 6px 1px rgba(255, 193, 59, 0.3);
        border-radius: 4px 4px 4px 4px;
        text-align: center;
        line-height: 40px;
        color: #2B2B2B;
        margin-left: 32px;
        cursor: pointer;
    }
}

.tables {
    width: 100%;
    margin-top: 60px;
    padding: 0 24px 32px;

    ::v-deep .nana {
        text-align: center;
        // height: 100%;
        // display: flex;
        // flex-wrap: wrap;

        // .cell {
        //     height: 84px;
        // }
    }

    ::v-deep .el-table_1_column_11 {
        text-align: center;
    }

    .el-button {
        // width: 72px;
        height: 24px;
        background: #1890FF;
        box-shadow: 0px 3px 6px 1px rgba(24, 144, 255, 0.3);
        border-radius: 4px 4px 4px 4px;
        // line-height: 24px;
        text-align: center;
        font-size: 12px;
        font-weight: 400;
        color: #FFFFFF;
        padding: 6px 12px;
    }

    .el-button:nth-child(2) {
        background: #D4252C;
        box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
    }

    .el-button:nth-child(3) {

        background: #4CAF50;
        box-shadow: 0px 3px 6px 1px rgba(212, 37, 44, 0.3);
    }


    ::v-deep .is-leaf {
        font-size: 14px;
        font-weight: 400;
        color: #707070;
    }

    ::v-deep .el-table__row {
        height: 84px;
        // line-height: 84px;

        .el-table__cell {
            font-size: 14px;
            // font-weight: bold;
            color: #333333;

            .cell {
                // height: 24px;
                white-space: nowrap;
            }
        }
    }

    .paging {
        display: flex;
        justify-content: flex-end;
        position: relative;
        // float: right;
        margin-top: 32px;
        // background-color: #FFFFFF;
    }

}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
    width: 649px;
    //     /*滚动条宽度*/
    height: 8px;
    //     /*滚动条高度*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-track {
    border-radius: 0px;
    /*滚动条的背景区域的圆角*/

    background: rgba(153, 153, 153, 0.2);
    /*滚动条的背景颜色*/
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 4px;
    /*滚动条的圆角*/

    background: #999999;
    /*滚动条的背景颜色*/
}

.detail {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 100;

    .detailBox {
        position: absolute;
        left: 50%;
        top: 90px;
        transform: translateX(-50%);
        width: 800px;
        // padding-bottom: 15px;
        background: #FFFFFF;
        border-radius: 4px 4px 4px 4px;

        .detailTitle {
            width: 100%;
            height: 64px;
            line-height: 64px;
            padding-left: 24px;
            border-bottom: 1px solid #999999;
            font-size: 26px;
            font-weight: 400;
            color: #333333;
        }

        .detailName {
            display: flex;
            height: 84px;
            padding-left: 24px;
            border-bottom: 1px solid #999999;

            .detailNameLeft {
                flex: 1;
                display: flex;

                .img {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin: auto 0;

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }

                .names {
                    // margin-left: 8px;
                    margin: auto 0 auto 8px;

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        color: #333333;
                    }

                    p:nth-child(2) {

                        color: #999999;
                    }
                }
            }

            .detailNameRight {
                flex: 1;
                margin: auto 0;
                font-size: 14px;
                font-weight: 400;
                color: #333333;
                padding-left: 48px;

                p:nth-child(2) {
                    color: #999999;
                }
            }
        }

        .orderInformation {
            margin-top: 24px;
            padding-left: 24px;
            padding-right: 11px;
            width: 50%;
            border-right: 1px solid #999999;

            .orderTitle {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
            }

            .orderContent {
                // width: 50%;
                display: flex;
                justify-content: space-between;
                margin-top: 24px;


                .drderL {
                    ul {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 16px;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }
                }

                .drderR {
                    ul {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                            text-align: right;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .formation {
            border-top: 1px solid #999999;
            margin-top: 24px;
            // padding-left: 24px;

            .orderTitle {
                font-size: 18px;
                font-weight: bold;
                color: #333333;
                margin-top: 24px;
                padding-left: 24px;
            }

            .formationContent {
                display: flex;
                padding-bottom: 24px;

                .formationContentL {
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                    border-right: 1px solid #999999;
                    padding-right: 11px;
                    padding-left: 24px;

                    ul:nth-child(1) {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 16px;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }

                    ul:nth-child(2) {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                            text-align: right;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }
                }

                .formationContentR {
                    width: 50%;
                    display: flex;
                    justify-content: space-between;
                    margin-top: 24px;
                    padding: 0 23px 0 10px;

                    ul:nth-child(1) {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #999999;
                            margin-top: 16px;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }

                    ul:nth-child(2) {
                        li {
                            font-size: 16px;
                            font-weight: 400;
                            color: #333333;
                            margin-top: 16px;
                            text-align: right;
                        }

                        li:nth-child(1) {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .close {
            height: 55px;
            border-top: 1px solid #999999;
            line-height: 55px;
            overflow: hidden;

            .btn {
                float: right;
                margin-right: 32px;
                cursor: pointer;
            }
        }
    }


}
</style>