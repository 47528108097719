<template>
    <div>
        <RentBox_Component></RentBox_Component>
    </div>
</template>

<script>
import RentBox_Component from '../../components/PersonalCenter/RentBox_Component.vue'
export default {
    name: "RentBox",
    components: { RentBox_Component }
}
</script>

<style>

</style>